.container {
  margin-top: 0.5rem;
}

.topText {
  text-align: center;

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 30px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
  }
}
