.App {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  background-color: #5c00c7;
}
